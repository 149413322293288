import https from "./https"

const me = {
  getProfile() {
    return https.get('admin/me')
  },
  updateProfile (params) {
    return https.put('admin/me/profile', params)
  },
  updateExtraProfile(params) {
    return https.put('admin/me/extra-profile', params)
  },
}

export default me
